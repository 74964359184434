<template>
  <div class="connect">
    <img class="lock" src="@/assets/imgs/new_lock.png" alt="">
    <img class="logo" src="@/assets/imgs/new_logo.png" alt="">
    <img class="world" src="@/assets/imgs/new_world.png" alt="">
    <div class="text">Your Web 3.0 Super App</div>
    <div class="btn" @click="init">Connect</div>
  </div>
</template>

<script setup>
  import { useRouter } from 'vue-router'
  import { showToast } from "vant";
  import i18n from "@/i18n";

  const router = useRouter()

  const Web3Modal = window.Web3Modal.default;
  const WalletConnectProvider = window.WalletConnectProvider.default;

  // Web3modal instance
  let web3Modal, network, provider, selectedAccount;
  let injectedWeb3 = null;
  const infura_key = 'c3e254116c559ae5e58746e69fb38787';
  let provider1, signer;
  let gasPrice, usdtContract, landContract, amaContract, blockNftContract, conContract;

  async function init() {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          bridge: "https://uniswap.bridge.walletconnect.org",
          rpc: {
            56: 'https://bsc-dataseed.binance.org/',
            66: 'https://exchainrpc.okex.org',
            97: 'https://data-seed-prebsc-1-s3.binance.org:8545/',
            137: 'https://polygon-rpc.com/',
          },
          // Mikko's test key - don't copy as your mileage may vary
          infuraId: infura_key,
        }
      },
    };
    web3Modal = new Web3Modal({
      cacheProvider: false, // optional
      providerOptions, // required
      disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
    });
    try {
      provider = await web3Modal.connect();
      provider.enable()
    } catch (e) {
      console.log("Could not get a wallet connection", e);
      alert("cannot connect wallet");
      return;
    }
    // Subscribe to accounts change
    provider.on("accountsChanged", async (accounts) => {
      await fetchAccountData();
    });
    // Subscribe to chainId change
    provider.on("chainChanged", async (chainId) => {
      await fetchAccountData();
    });
    // Subscribe to networkId change
    provider.on("networkChanged", async (networkId) => {
      await fetchAccountData();
    });
    provider.on("disconnect", async (code, reason) => {
      console.log(code, reason);
      await onDisconnect();
    });
    await fetchAccountData(provider);
  }

  async function fetchAccountData() {
    const web3 = new Web3(provider);
    injectedWeb3 = web3;
    const accounts = await web3.eth.getAccounts();
    selectedAccount = accounts[0];
    network = await web3.eth.net.getId();
    console.log(network + ': ' + selectedAccount);
    if (network != 56) {
      showToast(i18n.global.t('wTips'))
      await onDisconnect();
    } else {
      sessionStorage.setItem('address', selectedAccount)
      router.push('/')
    }
  }
  async function onDisconnect() {
    console.log("Killing the wallet connection", provider);
    // TODO: Which providers have close method?
    if (sessionStorage.getItem('address')) {
      await web3Modal.clearCachedProvider();
      await provider.disconnect()
      provider = null;
      selectedAccount = null;
      sessionStorage.removeItem('address')
    }
  }
</script>

<style lang="less" scoped>
.connect{
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .lock{
    width: 350px;
  }
  .logo{
    width: 146px;
    margin: -38px 0 18px 0;
  }
  .world{
    width: 160px;
    margin-bottom: 12px;
  }
  .text{
    font-size: 14px;
    color: #ffffff;
    margin-bottom: 56px;
  }
  .btn{
    width: 327px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #06C8A1;
    border-radius: 15px;
    font-size: 13px;
    font-weight: 500;
  }
  .btn:hover{
    background: #33F88F;
  }
}
</style>
